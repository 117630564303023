define("ev/templates/settings/social", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RchL18FG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"success\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"green-text\"],[12],[2,\"Social successfully updated!\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"md-form input-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group-prepend\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-text social-icon instagram\"],[12],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@fixedWidth\"],[\"instagram\",\"fab\",true]],null],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-text settings-social-prefix\"],[12],[2,\"@\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@id\",\"@value\"],[\"profile_instagram\",[32,0,[\"social_instagram\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"md-form input-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group-prepend\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-text social-icon snapchat\"],[12],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@fixedWidth\"],[\"snapchat-ghost\",\"fab\",true]],null],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-text settings-social-prefix\"],[12],[2,\"@\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@id\",\"@value\"],[\"profile_snapchat\",[32,0,[\"social_snapchat\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-primary mt-3\"],[24,5,\"margin-left: 0;\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"updateSocial\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"check\"]],null],[2,\" \"],[1,[30,[36,2],[\"settings.save\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "ev/templates/settings/social.hbs"
    }
  });

  _exports.default = _default;
});